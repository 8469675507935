$validation-error-color: #ff4343;


$alert-close-icon-color: #ffffff;
$alert-info-background-color: #e6f9ff;
$alert-info-text-color: #0099cc;
$alert-success-background-color: #17945a;
$alert-success-text-color: #ffffff;
$alert-warning-background-color: #f9f2cd;
$alert-warning-text-color: #9a8613;
$alert-error-background-color: #fff6f6;
$alert-error-text-color: #ff4343;
$alert-progress-background-color: #000000;