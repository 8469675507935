// example of usage @include calc( width, '100% - 2px')
@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: -o-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

// example of usage @include font-face('MyriadPro-Regular', 'assets/fonts/MyriadProRegular')
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal, $stretch: normal) {
    @font-face {
        font-family: $font-name;
        src: url($file-name + '.eot');
        src: url($file-name + '.eot?#iefix') format('embedded-opentype'), url($file-name + '.woff') format('woff'),
            url($file-name + '.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
        font-stretch: $stretch;
    }
}

@mixin box-sizing($value) {
    -webkit-box-sizing: $value;
    -moz-box-sizing: $value;
    box-sizing: $value;
}

@mixin wrap-text-ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// example of usage: @include box(100px, 50px);
@mixin box($height, $width) {
    height: $height;
    width: $width;
}

// example of usage: @include clearfix;
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

// example of usage: @include clearfix;
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin position($position, $args) {
    @each $o in top right bottom left {
        $i: index($args, $o);
        @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
            #{$o}: nth($args, $i + 1);
        }
    }
    position: $position;
}

// Positioning helpers
// example of usage: @include absolute(top 100% left 0);
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

// example of usage: @include fixed();
@mixin fixed($args: '') {
    @include position(fixed, $args);
}

// example of usage: @include relative();
@mixin relative($args: '') {
    @include position(relative, $args);
}

// example of usage: @include sticky();
@mixin sticky($args: '') {
    @include position(sticky, $args);
}

// example of usage: @include font-size(16);
@mixin font-size($size, $base: 16) {
    font-size: $size; // fallback for old browsers
    font-size: ($size / $base) * 1rem;
}

// example of usage: @include prefix('box-sizing', 'border-box', moz webkit);
@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
    @if $vendors {
        @each $vendor in $vendors {
            #{"-" + $vendor + "-" + $property}: #{$value};
        }
    }
    @if $default {
        #{$property}: #{$value};
    }
}

// example of usage: @include gradient(#07c, #06f, vertical);
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

// example of usage: @include @include center-block;
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin rtl {
    @at-root [dir='rtl'] #{&} {
        @content;
    }
}
