@import '../common/colors';
@import '../mixins/mixins';

$alert-width: 768px;
$alert-padding: 8px 8px 8px 48px;
$alert-border-radius: 4px;
$alert-box-shadow: 0px 5px 8px $alert-close-icon-color;
$alert-background-image-size: 30px;

$message-font-size: 16px;
$message-line-height: 24px;
$title-font-size: 18px;
$title-line-height: 22px;

// Position
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.toast-top-center {
    top: 30px;
    right: 0;
    width: 100%;
}
.toast-bottom-center {
    bottom: 30px;
    right: 0;
    width: 100%;
}
.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}
.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-left {
    top: 30px;
    left: 30px;
}
.toast-top-right {
    top: 30px;
    right: 30px;
}
.toast-bottom-right {
    right: 30px;
    bottom: 30px;
}
.toast-bottom-left {
    bottom: 30px;
    left: 30px;
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: $alert-width;
    margin-left: auto;
    margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

// Toast
.toast-container {
    pointer-events: none;
    @include fixed();
    z-index: 999999;

    .ngx-toastr {
        @include relative();
        overflow: hidden;
        margin: 0 0 6px;
        padding: $alert-padding;
        width: $alert-width;
        background-position: 8px 8px;
        background-repeat: no-repeat;
        background-size: $alert-background-image-size;
        pointer-events: auto;
        border-radius: $alert-border-radius;
        box-shadow: $alert-box-shadow;

        .toast-title {
            font-weight: bold;
            font-size: $title-font-size;
            line-height: $title-line-height;
        }
        .toast-message {
            word-wrap: break-word;
            overflow: auto;
            max-height: 300px;
            font-size: $message-font-size;
            line-height: $message-line-height;
            margin-bottom: 5px;
        }
        .toast-message a:hover {
            text-decoration: underline;
        }
        .toast-close-button {
            float: right;
            background: transparent;
            border: 0;
            padding: 0;
            cursor: pointer;

            span {
                font-family: Arial, sans-serif;
                font-size: 23px;
                font-weight: bold;
                line-height: 23px;
                color: $alert-close-icon-color;
            }
        }
        .toast-progress {
            @include absolute();
            left: 0;
            bottom: 0;
            height: 4px;
            background-color: $alert-progress-background-color;
            @include opacity(0.3);
        }
    }

    .ngx-toastr.toast-info {
        background-color: $alert-info-background-color;
        box-shadow: none;

        div.toast-title,
        div.toast-message {
            color: $alert-info-text-color;
        }
    }
    .ngx-toastr.toast-success {
        background-color: $alert-success-background-color;
        box-shadow: none;

        div.toast-title,
        div.toast-message {
            color: $alert-success-text-color;
        }
    }
    .ngx-toastr.toast-error {
        background-color: $alert-error-background-color;
        box-shadow: none;

        div.toast-title,
        div.toast-message {
            color: $alert-error-text-color;
        }
    }
    .ngx-toastr.toast-warning {
        background-color: $alert-warning-background-color;
        box-shadow: none;

        div.toast-title,
        div.toast-message {
            color: $alert-warning-text-color;
        }
    }
}
