/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
 
@import 'node_modules/ngx-sharebuttons/themes/material.scss';
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';
@import 'common/colors';

@import 'components/alerts';


.validation-error {
    color: $validation-error-color;
    font-size: 12px;
}


* {
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #242424;
}